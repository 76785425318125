#root {
  background: url("https://travispokertimer.com/timer/images/TPT_desktop.jpg")
    no-repeat center #eee;
  background-size: cover;
}

.ui.segment {
  background: none;
}

.ui.modal > .header {
  border-bottom: none !important;
}

.ui.list .list > .item .header,
.ui.list > .item .header {
  color: #fff;
}

.ui.statistic > .value,
.ui.statistics .statistic > .value {
  color: white;
  font-family: "flamamediumregular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: start;
  font-size: 7vw !important;
}

.ui.statistic > .label,
.ui.statistics .statistic > .label {
  color: rgba(255, 255, 255, 0.8);
  font-family: "flamamediumregular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: lighter;
  text-transform: capitalize;
  font-size: 1.6vw;
  letter-spacing: 0.2;
  text-align: start;
}

.ui.horizontal.statistic > .value,
.ui.horizontal.statistics .statistic > .value {
  font-size: 3vw !important;
}

#app {
  margin-top: 40px;
  margin-left: 50px;
}

#progressInput {
  margin: 20px auto;
  width: 30%;
}

.circle-background,
.circle-progress {
  fill: none;
}

.circle-progress2 {
  background: yellow;
  fill: none;
  stroke: rgba(77, 103, 76, 255);
}

.circle-dashes {
  stroke: rgba(183, 189, 179, 255);
  fill: none;
}

.circle-progress {
  stroke: rgba(255, 255, 255, 0.8);
}

svg {
  display: block;
  margin: auto;
  width: 55% !important;
  height: auto;
}

#clockShow {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

#clockInfo {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
}

#clockInfo div {
  border-radius: 6px;
  text-align: center;
  pointer-events: auto;
  color: rgba(9, 48, 4, 255);
}

#clockInfoTxtRound,
#clockInfoTxtDigits,
#clockInfoTxtTotal {
  background: rgba(255, 255, 255, 0.8);
}

#clockInfoTxtRound {
  width: 10vw;
  font-size: 2.2vw;
  padding: 0.4em 0;
  margin-bottom: 5px;
}

#clockInfoTxtDigits {
  width: 22vw;
  font-size: 7vw;
  padding: 0 15px;
  line-height: 90%;
  margin-bottom: 5px;
}

#clockInfoTxtTotal {
  font-size: 2vw;
  width: 21vw;
  padding: 0.5rem 0;
  margin-bottom: 5px;
}

#clockInfoTxtButtons {
  background: none;
}

.leftRight {
  font-family: "flamamediumregular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: bold;
  min-width: 20px;
  border: 1px solid #fff;
  background: linear-gradient(rgba(94, 94, 94), rgba(22, 22, 22));
  padding: 2px 2px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  color: white;
  font-size: 11px;
  text-decoration: none;
  vertical-align: middle;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  cursor: pointer;
  display: inline;
}
.go {
  font-family: "flamamediumregular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 1vw;
  min-width: 6em;

  border: 1px solid #fff;
  background: linear-gradient(rgba(94, 94, 94), rgba(22, 22, 22));
  padding: 3px 4px;
  -webkit-border-radius: 11px;
  -moz-border-radius: 11px;
  border-radius: 11px;
  color: white;
  text-decoration: none;
  vertical-align: middle;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  cursor: pointer;
  display: inline;
  margin-left: 2px;
  margin-right: 2px;
}

.go a {
  text-decoration: none;
}
