@font-face {
  font-family: "flamamediumregular";
  src: url("flamamedium-webfont.woff2") format("woff2"),
    url("flamamedium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "flamamediumregular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.active-input {
  border: 1px solid red;
}
