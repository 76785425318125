/* Be aware that *!important* is being used throughout the code, the semantic-ui framework uses it, and it's almost a requirement for overriding its classes.*/

/* CSS Variables*/

:root {
  --main-bg-color: rgba(1, 51, 0, 255);
  --secondary-bg-color: rgba(35, 54, 35, 255);
  --modal-main-color: rgba(242, 255, 239, 255);
  --modal-bg-content: rgba(1, 27, 2, 255);
  --border-main-color: rgba(130, 154, 130, 255);
  --rounds-main-bg-color: rgba(192, 192, 192, 255);
}

/* Chrome Config */

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar {
  width: 8px !important;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(127, 125, 126, 255) !important;
  border-radius: 5px !important;
  border: 1px solid white;
}

.active-input {
  border: 1px solid #333333 !important;
}

.save-blinds-popup {
  background-color: var(--main-bg-color) !important;
  color: #fff !important;
  border: 1px solid #fff !important;
}

.save-blinds-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.save-blinds-popup-button {
  margin-top: 0.75rem;
}

.delete-confirm-popup {
  border: 2px solid #fff !important;
}

.delete-confirm-popup,
.delete-confirm-popup > .content,
.delete-confirm-popup > .actions {
  background-color: var(--main-bg-color) !important;
  color: #fff !important;
}

.login-container {
  background-color: var(--modal-bg-content) !important;
  border: 1px solid #fff !important;
  max-width: 450px;
}
.login-title {
  padding-top: 1.5rem;
  color: #fff !important;
  opacity: 0.9 !important;
}
.error-modal-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-padding {
  padding: 0 1.5em;
}
#create-tournament-button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
}
.rounds-action-buttons,
.standard-buttons,
.modal-prizes-screen-save-button button,
#create-tournament-button button,
.end-game-action-buttons button,
.submit-stats-action-button button {
  width: 8rem;
  font-size: 1vw !important;
  background-color: rgba(60, 79, 60, 255) !important;
  color: #fff !important;
  text-decoration: none !important;
  vertical-align: middle !important;
  text-transform: uppercase !important;
  letter-spacing: 0.1em !important;
  cursor: pointer !important;
}
.add-players-button button {
  white-space: nowrap;
  font-size: 1vw !important;
  background-color: rgba(60, 79, 60, 255) !important;
  color: #fff !important;
  text-decoration: none !important;
  vertical-align: middle !important;
  text-transform: uppercase !important;
  letter-spacing: 0.1em !important;
  cursor: pointer !important;
}
/*.end-game-action-buttons button:first-child {
  background-color: rgba(3, 8, 2, 255) !important;
}*/

.standard-buttons {
  font-family: 'flamamediumregular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 6em;
  border: 1px solid #fff !important;
  background: linear-gradient(rgba(94, 94, 94), rgba(22, 22, 22)) !important;
  padding: 3px 4px !important;
  -webkit-border-radius: 11px !important;
  -moz-border-radius: 11px !important;
  border-radius: 11px !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.menu-container {
  border-bottom: 1px solid rgba(210, 222, 212, 255) !important;
  background-color: rgba(3, 8, 2, 255) !important;
  padding: 0 !important;
}

div.item > .ui.button {
  background: rgba(17, 16, 17, 255);
  color: rgba(200, 200, 200, 255);
  border: none;
  text-transform: capitalize;
  border-radius: 0;
}

.content-container {
  width: 95% !important;
  height: 91.5vh;
  border-radius: 4px;
}

.content-primary-container {
  height: calc(64vh - 40px);
  margin: 20px 0 !important;
  background: rgb(6, 28, 5);
  background: linear-gradient(
    90deg,
    rgba(6, 28, 5, 1) 0%,
    rgba(6, 30, 4, 1) 18%,
    rgba(9, 40, 5, 0) 43%,
    rgba(11, 50, 5, 1) 64%,
    rgba(6, 28, 5, 0.7189076314119398) 90%
  );
  border-radius: 7px;
}

.content-primary-container-columns {
  height: 100%;
}

.content-secondary-container {
  height: 27.5vh;
  gap: 15px;
}

.scroll-in-container {
  height: 100%;
  border-radius: 7px;
  overflow: auto;
  overscroll-behavior: contain;
}

.scroll-internal-players-list {
  height: 100%;
  overflow: auto;
  overscroll-behavior: contain;
}

div.content-secondary-columns-bgColor.scroll-in-container.internal-box-timer {
  padding: 1rem !important;
}

.tournament-name {
  font-family: 'flamamediumregular' !important;
  font-size: 1vw;
  font-weight: lighter;
  margin: 1rem 0 0 0 !important;
}

.content-secondary-columns-bgColor {
  background-color: rgba(10, 10, 10, 0.85);
}

.content-secondary-columns-bgColor:first-child,
.content-secondary-columns-bgColor:last-child {
  background-color: rgba(1, 11, 2, 255);
}

.ui.statistics:first-child {
  flex-direction: column;
  margin: 0;
}

.content-primary-last-column div:first-child {
  align-content: flex-end;
}

div.content-primary-last-column-label:first-child > div:first-child .label,
div.content-primary-last-column-label:last-child > div:last-child .value {
  color: rgba(255, 255, 255, 0.6);
  text-align: end;
  font-size: 1.4vw !important;
}

div.content-primary-last-column-label:last-child > div:last-child .value {
  font-size: 7vw !important;
}

div.next-round > div:nth-child(1),
div.next-round > div:nth-child(2),
div.next-round > div:nth-child(3) {
  width: 17.1rem !important;
}

.tournament-players-names {
  width: 15vw !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-primary-chips-indicator {
  width: 40vw;
  border: 2px solid red;
  text-align: center;
  margin: 0 auto;
  padding: 10px;
  visibility: hidden;
}

.chips-statistics {
  margin: 0em 0em 0.2em 0 !important;
  width: 100%;
  justify-content: space-between;
}

div.stat-label-timer {
  margin-left: 0 !important;
  margin-top: 0 !important;
}

.tournament-players-list {
  margin: -1.5em 0;
  padding-right: 0.5em;
}

div.tournament-players-list > button.ui.icon {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  font-size: 0.6em;
  background-color: rgba(46, 46, 46, 255);
  color: rgba(224, 225, 226, 255);
  border: 1px solid rgba(180, 180, 180, 255);
}

div.prize-pool-statistic-container.ui.statistics.add-padding {
  width: 100%;
  margin-top: 0px;
}

.prize-pool-cash-total {
  margin: 0 !important;
}

.prize-pool-cash-total .label {
  padding-right: 1em;
  margin-left: 0 !important;
}

.prize-pool-statistic {
  flex-direction: column !important;
  margin: 0.3em 0 !important;
  align-items: start !important;
}

.prize-pool-statistic .label {
  margin-left: 0 !important;
  font-size: 1em !important;
}

.prize-pool-statistic .value {
  margin-right: 0.5em;
}

/*  Modal style  */

.modal-dimmer-bg-color {
  background-color: var(--main-bg-color) !important;
  border: 2px solid var(--border-main-color) !important;
  color: var(--modal-main-color) !important;
}

.modal-bg-content-container {
  background-color: var(--modal-bg-content) !important;
  width: 85% !important;
  margin: 1.8rem auto !important;
  color: var(--modal-main-color) !important;
  padding: 1.8rem !important;
}

/* Rounds screen CSS */
.modal-rounds-screen {
  font-size: 1.3rem !important;
}
.round-header {
  width: 85%;
  margin: 2rem auto;
  font-size: 3rem;
  color: var(--modal-main-color) !important;
}

.round-buttons-container {
  width: 85%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.next-prev-button {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid var(--border-main-color) !important;
  background-color: var(--secondary-bg-color) !important;
  color: rgba(237, 249, 238, 255) !important;
  margin-left: 0 !important;
  text-align: center !important;
  padding: 0 !important;
}

.rounds-action-buttons {
  margin-left: 5px !important;
  padding: 1rem !important;
  background-color: rgba(60, 79, 60, 255) !important;
  padding: 3px 4px !important;
  -webkit-border-radius: 11px !important;
  -moz-border-radius: 11px !important;
  border-radius: 11px !important;
}

.round-buttons-container b {
  margin-left: 1rem;
}

.modal-break-column-input-wrapper {
  width: 2rem;
  height: 2rem;
  background: transparent;
  text-align: center !important;
  padding: 4px 0;
  /* border: 1px solid var(--rounds-main-bg-color) !important; */
}

.modal-break-column-input-wrapper input {
  width: 20px;
  height: 17px;
  background: var(--rounds-main-bg-color) !important ;
}

.modal-rounds-column-headers h2 {
  font-size: 1.5rem !important;
  font-weight: 400 !important;
}

.modal-bg-content-container th,
.modal-bg-content-container td {
  background-color: var(--modal-bg-content) !important;
  color: var(--modal-main-color) !important;
}

.modal-bg-content-container .content-editable {
  background-color: var(--rounds-main-bg-color) !important;
}

.modal-round-content-container {
  width: 100%;
}

.align-text-end,
.round-column-header {
  text-align: end !important;
}

.align-text-end {
  padding: 9px 0 0 10px;
}

.modal-round-content-container div.content-editable {
  height: 2.3rem;
  padding: 9px 0 0 10px;
  color: black;
}

.duration-column {
  height: 2.3rem !important;
  width: 100% !important;
}

.duration-column input {
  box-shadow: none !important;
  border: none !important;
  padding: 9px 0 0 10px !important;
  background: transparent;
}

.players-modal .players-modal-header,
.players-modal .players-extras-modal-header,
.players-label {
  color: var(--modal-main-color) !important;
  background-color: transparent !important;
}
/* General style */
.rounds-action-buttons,
.add-players-button button,
.modal-prizes-screen-save-button button,
.players-modal-content button,
.end-game-action-buttons button,
.submit-stats-action-button button {
  border: 2px solid var(--border-main-color) !important;
}

.players-modal .players-modal-header,
.players-modal .players-extras-modal-header,
.players-modal-secodary-headers,
.modal-rounds-column-headers h2 {
  font-family: 'flamamediumregular' !important;
}
.players-modal .player-extras-dialog-label {
  color: #fff !important;
}

/* Tournament screen */

.list-of-tournament a {
  color: #fff !important;
}

/* Players screen */

.players-modal .players-modal-header,
.players-modal-bottom {
  width: 85% !important;
  margin: 0 auto !important;
  padding-left: 0 !important;
  font-size: 3rem !important;
  font-weight: 400 !important;
}

.players-modal-bottom {
  margin-bottom: 2rem !important;
}

.players-modal .players-extras-modal-header {
  width: 85% !important;
  margin: 0 auto !important;
  padding-left: 0 !important;
  font-size: 2rem !important;
  font-weight: 400 !important;
}

.modal-bg-content-container input {
  background-color: #c0c0c0 !important;
  width: 100%;
}

.players-table {
  width: 85%;
  display: flex !important;
  margin: 0 auto;
  margin-bottom: 25px !important;
}

.dropdown-players-menu {
  flex-grow: 1;
}

.players-table-item {
  text-align: center;
}

.players-label {
  font-size: 1.4vw !important;
  font-weight: lighter !important;
}

.players-table input,
.players-table-dropdown {
  background-color: #c0c0c0 !important;
  width: 100%;
}

.players-table input,
.players-table-dropdown {
  background-color: #c0c0c0 !important;
  width: 100%;
}

.players-table-dropdown {
  margin-top: 0.4rem;
}

.players-table-dropdown {
  font-size: 1vw !important;
}
.dropdown-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.prizes-modal-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}

.tournament-results-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.tournament-results-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 14rem;
  padding: 5px 10px !important;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  border-radius: 12px !important;
  font-size: 0.8vw;
  color: rgb(240, 238, 238) !important;
  background-color: rgba(60, 79, 60, 255) !important;
  text-decoration: none !important;
  text-transform: uppercase !important;
  letter-spacing: 0.1em !important;
  cursor: pointer !important;
}

.width-150px {
  width: 200px !important;
}

.add-players-button {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 14rem;
}

.add-players-button button,
.end-game-action-buttons button {
  margin-left: 7px !important;
  padding: 5px 10px !important;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  border-radius: 12px !important;
}

.end-game-action-buttons button {
  width: 9rem !important;
}

#create-tournament-button button,
.submit-stats-action-button button {
  width: 15vw !important;
  margin-left: 7px !important;
  padding: 10px auto !important;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  border-radius: 12px !important;
}

.players-table .default,
.add-players-button button,
.create-tournament-button button {
  color: rgb(240, 238, 238) !important;
}

.set-white-color {
  color: #fff !important;
}

.end-game-action-buttons {
  display: flex;
  justify-content: flex-end;
}

.submit-stats-action-button {
  display: flex;
  justify-content: flex-end;
  margin: 1rem auto !important;
}
.submit-stats-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(60, 79, 60, 255);
  color: #fff;
  font-size: 1vw;
  font-family: 'Lato', sans-serif;
  padding: 2px 15px;
  text-decoration: none;
  vertical-align: middle;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  cursor: pointer;
  border: 2px solid var(--border-main-color);
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  margin-top: 1rem;
}

.submit-stats-action-button button {
  background-color: rgba(60, 79, 60, 255) !important;
}

.players-modal-content button {
  width: 25px !important;
  height: 25px !important;
  border-radius: 50% !important;
  margin-left: 0 !important;
  background-color: var(--secondary-bg-color) !important;
  color: rgba(171, 185, 172, 255) !important;
  text-align: center !important;
  padding: 0 !important;
}

.player-image-modal {
  margin-right: 1rem;
}

.players-modal-secodary-headers {
  display: flex;
  color: rgba(171, 185, 172, 255) !important;
  font-size: 1.3rem !important;
}

.players-modal-secodary-headers:first-child {
  margin-left: 4.5rem;
}

.nationality-header {
  padding-top: 2rem;
}

.profile-header {
  padding-top: 1rem;
}

h2.players-modal-secodary-headers,
label.players-modal-secodary-headers {
  color: rgba(237, 249, 238, 255) !important;
}

p.tournament-players-names-modal {
  margin-top: 1rem !important;
}
.tournament-players-names img {
  margin-right: 1rem !important;
}
.players-modal-name-item {
  display: flex;
  color: rgba(171, 185, 172, 255) !important;
}

.players-modal-rows {
  margin: 0 !important;
  padding: 0 !important;
}

/* Prizes Screen */

.modal-prizes-screen-header.header,
.modal-prizes-screen-save-button {
  background-color: transparent !important;
}

.modal-prizes-screen-save-button button {
  width: 8rem !important;
  margin-left: 7px !important;
  padding: 5px 10px !important;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  border-radius: 12px !important;
}

.modal-prizes-screen-header.header h1 {
  margin: 1.7rem auto;
  font-size: 3rem;
  font-family: 'flamamediumregular' !important;
}

.modal-prizes-screen-header.header h1,
.modal-prizes-screen-content > div label {
  color: var(--modal-main-color) !important;
}

.h3-prizes-title {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.prizes-screen-form-content {
  margin-top: 1rem !important;
}

.prizes-screen-form-content div:nth-child(1),
.rounding-field {
  font-size: 1.4rem !important;
  font-weight: lighter !important;
}

.rounding-field-input {
  font-size: 1vw !important;
}

.prizes-screen-form-content div:nth-child(1) {
  margin-left: 1rem !important;
}

.prizes-screen-form-content div:nth-child(2),
.prizes-screen-form-content div:nth-child(3) {
  margin: 0.5rem !important;
}

/* //////////////////////////////////////////////////////////////////////
// @Media Queries
//////////////////////////////////////////////////////////////////////// */

@media only screen and (max-width: 1280px) {
  svg {
    width: 60% !important;
  }
  #clockInfoTxtRound {
    width: 18vw;
  }
  #clockInfoTxtDigits {
    width: 25vw;
  }
  #clockInfoTxtTotal {
    width: 22vw;
  }
  .prize-pool-statistic-container {
    width: 100%;
  }
  .players-modal-content,
  .players-table {
    width: 95% !important;
  }
  .round-header {
    font-size: 3vw !important;
  }
  .players-modal .players-modal-header {
    font-size: 3vw !important;
  }
  .players-modal .players-extras-modal-header {
    font-size: 3vw !important;
  }
  .add-players-button,
  .players-table-dropdown {
    margin-top: 0px;
  }
  .add-players-button button,
  .players-table-dropdown {
    font-size: 1vw !important;
  }
  .tournament-name {
    font-size: 1.4vw;
  }
  .tournament-menu-items,
  .tournament-results-button {
    font-size: 1.2vw;
  }

  .prizes-screen-form-content div:nth-child(2),
  .prizes-screen-form-content div:nth-child(3) {
    margin: 0.5rem !important;
    font-size: 0.7rem !important;
  }
  #players-names-in-tournament {
    width: 15vw !important;
    white-space: normal !important;
  }
}
.ui.fullscreen.modal > .close {
  color: #fff;
}

@media only screen and (max-width: 1080px) {
  svg {
    width: 75% !important;
  }
  #clockInfoTxtRound {
    width: 20vw;
  }
  #clockInfoTxtDigits {
    width: 26vw;
  }
  #clockInfoTxtTotal {
    width: 23vw;
  }
  .players-modal-content,
  .players-table {
    width: 90% !important;
  }
  .tournament-players-names-modal {
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 1rem !important;
  }
  .tournament-menu-items {
    font-size: 1.5vw;
  }
  .players-label {
    font-size: 1.7vw !important;
  }
  .players-table input {
    width: 6rem;
  }
  .players-table-dropdown {
    min-width: 6rem !important;
  }
  .modal-rounds-screen,
  .modal-rounds-column-headers h2 {
    font-size: 1.7vw !important;
  }
  .modal-bg-content-container {
    width: 90% !important;
  }
  .rounds-action-buttons {
    font-size: 1.2vw !important;
  }
  .modal-prizes-screen-save-button button {
    font-size: 1.2vw !important;
  }
  .ui.statistic > .label,
  .ui.statistics .statistic > .label {
    font-size: 1.8vw !important;
  }
  div.content-primary-last-column-label:first-child > div:first-child .label {
    font-size: 1.5vw !important;
  }
  div.content-primary-last-column-label:last-child > div:last-child .value,
  .ui.statistic > .value,
  .ui.statistics .statistic > .value {
    font-size: 8vw !important;
  }
  div.content-secondary-columns-bgColor.scroll-in-container.internal-box-timer {
    padding: 0.5rem !important;
  }
}

@media only screen and (max-width: 820px) {
  svg {
    width: 85% !important;
  }
  #clockInfoTxtRound {
    width: 24vw;
  }
  #clockInfoTxtDigits {
    width: 30vw;
  }
  #clockInfoTxtTotal {
    width: 27vw;
  }
  #clockInfoTxtDigits {
    font-size: 8vw;
  }
  .tournament-menu-items {
    font-size: 1.4vw;
  }
  #clockInfoTxtTotal {
    padding: 0.3rem 0;
  }
  .players-modal .players-modal-header {
    font-size: 4vw !important;
  }
  .players-label {
    font-size: 1.9vw !important;
  }
  .add-players-button button,
  .players-table-dropdown {
    font-size: 1.6vw !important;
  }
  .modal-bg-content-container {
    width: 95% !important;
  }
  .ui.form .fields {
    flex-wrap: nowrap;
  }
  .round-buttons-container {
    width: 95% !important;
  }
  .rounds-action-buttons {
    font-size: 1.5vw !important;
  }
  .modal-prizes-screen-save-button button {
    font-size: 1.5vw !important;
  }
  .ui.statistic > .label,
  .ui.statistics .statistic > .label {
    font-size: 2vw !important;
  }
  div.content-primary-last-column-label:first-child > div:first-child .label {
    font-size: 1.7vw !important;
  }
  div.content-primary-last-column-label:last-child > div:last-child .value,
  .ui.statistic > .value,
  .ui.statistics .statistic > .value {
    font-size: 10vw !important;
  }
  .ui.table:not(.unstackable) tr {
    display: flex !important;
  }
}
@media only screen and (max-width: 680px) {
  svg {
    width: 90% !important;
  }
  .ui.form .fields {
    flex-wrap: wrap;
  }
  .players-modal .players-modal-header {
    width: 90% !important;
  }
  .players-modal-content,
  .players-table {
    width: 95% !important;
  }
  .players-modal .players-modal-header {
    font-size: 5vw !important;
  }
  .players-label {
    font-size: 2vw !important;
  }
  .rounds-action-buttons {
    margin: 0.5rem 0.5rem !important;
  }
}
@media only screen and (max-width: 550px) {
  svg {
    width: 100% !important;
  }
  .add-players-button button,
  .players-table-dropdown {
    font-size: 1.9vw !important;
  }
  .players-table-dropdown,
  .add-players-button {
    margin-top: 1.5rem;
  }
  .players-modal .players-modal-header {
    font-size: 5.5vw !important;
  }
  .players-label {
    font-size: 2vw !important;
  }
}

iframe {
  display: none !important;
}

.hidden-button {
  background: none !important; /* No background color */
  border: none !important; /* No border */
  box-shadow: none !important; /* No box shadow */
  color: transparent !important; /* No text color */
  padding: 0 !important; /* No padding */
  margin: 0 !important; /* No margin */
  opacity: 0 !important; /* Make it invisible */
  cursor: default !important; /* Default cursor */
  width: 3rem;
  height: 3rem;
}

/* * { border:1px solid red !important; } */
